import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import Navbar from "../Navbar";
import InfiniteScroll from "react-infinite-scroll-component";
import { FiEdit } from "react-icons/fi"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux";
import { fetchTasks } from "../../Actions/TasksAction";
import { fetchCategory } from "../../Actions/CategoryAction";
import ModalPopup from "./ModalPopup";
import { useNavigate } from "react-router-dom";
import { sendData, searchTask } from "../../Actions/TasksAction";
import { Tooltip } from 'react-tooltip'
import "react-tooltip/dist/react-tooltip.css";


const Create = () => {
    const selector = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const [popular, setPopular] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [category, setCategory] = useState([]);
    const [show, setShow] = useState(false)
    const [UserInputs, setUserInputs] = useState([]);
    const [taskName, setTaskName] = useState("");
    const [dataValue, setDataValue] = useState([]);
    const [id, setId] = useState("");
    const [isClicked, setIsClicked] = useState(-1);
    const [promptHeading, setpromptHeading] = useState("")
    const [prompt, setPrompt] = useState("")
    const [descriptionData, setDescriptionData] = useState("")
    const [search, setSearch] = useState({
        start: 1,
        end: 15,
        cattegoryId: false,
        keyword: ""
    })
    const [taskLength, setTaskLength] = useState(0)
    const [isPromptAvail, setIsPromptAvail] = useState(true);
    const showAllTask = () => {
        setLoader(true);
    }
    const showAllCategory = () => {
        setLoader(true);
        dispatch(fetchCategory(setCategory, setLoader));
    }
    const infiniteScroll = (page = 1) => {
        // if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) {
        //     console.log("no");
        //     return;
        // } else {
        const scrolldiv = document.getElementById("scrollableDiv");
        setLoader(true);
        setSearch({
            ...search,
            start: page + 1
        })
        dispatch(fetchTasks(search, tasks, setTasks, setPopular, setTaskLength, setLoader, isClicked, setIsClicked))
        // }
    }

    const ActiveClass = (id) => {
        console.log(id);
        if (id === search?.cattegoryId) return;
        const localSearch = { ...search, start: 1, cattegoryId: id, keyword: "" };
        setSearch(localSearch);
        setIsClicked(1);
        setTasks([]);
        setPopular([]);
        setLoader(true)
        dispatch(fetchTasks(localSearch, tasks, setTasks, setPopular, setTaskLength, setLoader, isClicked, setIsClicked))
        //dispatch(fetchTasks())
        // const scrollID = document.getElementById('scrollableDiv')
        // scrollID.scrollIntoView({ behavior: 'auto' });
    }

    const Popup = (id, promptsName, description, prompts) => {
        setId(id);
        setpromptHeading(promptsName)
        setDescriptionData(description);
        setPrompt(prompts)
        const data = prompts;

        const regex = /\[(.*?)\]/g;
        const matches = data.match(regex);

        let values;
        if (matches) {
            values = matches.map(match => match.slice(1, -1));
            setDataValue(values);
            const result = values.reduce((objects, key) => {
                const obj = {}
                obj[key] = ""
                return [...objects, obj]
            }, []);

            setUserInputs(result);
        }
        setShow(true)
        if (prompts.includes("[")) {
            setIsPromptAvail(true);
        } else {
            setIsPromptAvail(false)
        }

    }

    const hidePopup = () => {
        setShow(false)
    }


    useEffect(() => {
        showAllTask();
        showAllCategory();

    }, []);

    useEffect(() => {
        infiniteScroll()
    }, []);

    const handleSearch = (e) => {
        setLoader2(true)
        setLoader(true)
        e.preventDefault();
        const Data = {
            cattegoryId: search.cattegoryId,
            keyword: search.keyword
        }
        dispatch(searchTask(Data, setTasks, setPopular, setLoader2, setLoader, search, setSearch, setTaskLength))
    }
    const handleChangeSearch = (e) => {
        let { name, value } = e.target;
        setSearch({
            ...search,
            [name]: value
        })
    }

    const onSetPrompts = (text) => {

        const regex = /\[(.*?)\]/g;
        const matches = text.match(regex);
        if (matches) {
            if (matches.length > 0) {
                matches.forEach((curElem) => {
                    text = text.replaceAll(curElem, "[X]")
                })
            }
        }
        return text
    }

    const handleScroll = () => {
        // const isAtEnd =
        //     window.innerHeight + window.pageYOffset >= document.body.scrollHeight;

        // if (isAtEnd) {
        //     // Window scrollbar is at the end
        //     console.log("Window scrollbar is at the end.");
        // }

        
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>

            <div>
                <TitleBar title="Projects" />
                <Navbar />


                <InfiniteScroll
                    dataLength={tasks.length}
                    next={() => infiniteScroll(search.start)}
                    hasMore={tasks.length < taskLength ? true : false}
                    style={{ minHeight: "calc(100vh - 100px)" }}
                //scrollableTarget="scrollDiv"
                >
                    <section className="siteWrap pb-5">

                        <div id="scrollDiv">
                            <div className="project-head" >
                                <div className="container">
                                    <h2><img src={require('../../images/party-popper.png')} /> {selector.user.name}, So, what exactly    do you have in mind?</h2>
                                    <p style={{ color: "#FFF" }}>Begin with selecting the content type from the options below.</p>
                                </div>
                            </div>

                            <div className="project-filter">

                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="project-filter-main">
                                                <ul>
                                                    <li><a className={search.cattegoryId === false ? "active" : ''} style={{
                                                        display: "inline-block",
                                                        background: "#19122c",
                                                        color: "#FFF",
                                                        padding: "12px 30px",
                                                        borderRadius: "50px",
                                                        cursor: "pointer"
                                                    }} onClick={() => {
                                                        if (search?.cattegoryId === false) return;

                                                        setTasks([]);
                                                        setPopular([]);
                                                        setLoader(true)
                                                        const localSearch = { ...search, start: 1, cattegoryId: false, keyword: "" };
                                                        setSearch(localSearch);
                                                        // const scrollID = document.getElementById('scrollableDiv')
                                                        // scrollID.scrollIntoView({ behavior: 'auto' });
                                                        dispatch(fetchTasks(localSearch, tasks, setTasks, setPopular, setTaskLength, setLoader, isClicked, setIsClicked))
                                                    }}>All</a></li>
                                                    {
                                                        (category.length > 0) ?
                                                            (category.map((list, index) => {
                                                                return (
                                                                    <li key={index} onClick={() => { ActiveClass(list.id) }}>
                                                                        <a style={{
                                                                            display: "inline-block",
                                                                            background: "#19122c",
                                                                            color: "#FFF",
                                                                            padding: "12px 30px",
                                                                            borderRadius: "50px",
                                                                            cursor: "pointer"
                                                                        }}
                                                                            className={list.id === search.cattegoryId ? 'active' : ''}
                                                                        >{list.categoryName}</a>
                                                                    </li>)
                                                            })) : <></>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="project-category"  >
                                <div className="container">
                                    <div className="project-category-block">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h2>Popular Task</h2>

                                        </div>
                                        <hr style={{ opacity: "1", color: "#f721a0" }} />
                                        <div className="row">
                                            {
                                                (popular.length > 0) ?
                                                    (popular
                                                        .map((task, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <div className="col-lg-6">
                                                                        <div className={`project-category-single`} style={{ cursor: "pointer" }}
                                                                            onClick={() => Popup(task.id, task.promptsName, task.description, task.promts)}
                                                                        >
                                                                            <div className="project-category-icon"><FiEdit /></div>

                                                                            <div className="project-category-txt">
                                                                                <h3>{task.promptsName}</h3>
                                                                                <p
                                                                                    style={{
                                                                                        color: "#FFF",
                                                                                        fontSize: 15
                                                                                    }}
                                                                                    className={`my-anchor-element${index}-popular`}
                                                                                >
                                                                                    {task.description !== "" ? task.description.slice(0, 40) + "..." : onSetPrompts(task.promts).slice(0, 40) + "..."}
                                                                                </p>
                                                                                <Tooltip anchorSelect={'.my-anchor-element' + index + '-popular'} place="bottom">
                                                                                    {task.description !== "" ? task.description : onSetPrompts(task.promts)}
                                                                                </Tooltip>
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                </React.Fragment>
                                                            );
                                                        })) :
                                                    <div className="col-md-12 text-center">
                                                        {loader ?
                                                            <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#f721a0", fontSize: "30px" }} />
                                                            : "No Data Found!"}
                                                    </div>
                                            }

                                        </div>
                                    </div>

                                    <div className="project-category-block" >
                                        <div className="col-md-12 p-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h2 >All Task</h2>
                                                <form onSubmit={(e) => { handleSearch(e) }}>
                                                    <input
                                                        type="text"
                                                        value={search.keyword}
                                                        className="add_form_control mr-1"
                                                        name="keyword"
                                                        placeholder="Search by task name & decription name"
                                                        onChange={handleChangeSearch}

                                                        style={{ padding: "0 10px", width: 400 }}

                                                    />
                                                    <button className="demoLink" type="submit">
                                                        {loader2 ? " Submitting" : "Submit "}
                                                        {loader2 ?
                                                            <i className="fa fa-spinner fa-spin mx-1" />
                                                            : ""}
                                                    </button>

                                                </form>
                                            </div>
                                            <hr style={{ opacity: "1", color: "#f721a0" }} />
                                        </div>
                                        <div id="scrollableDiv">
                                            <div className="row">
                                                {
                                                    (tasks.length > 0) ?
                                                        (tasks
                                                            .map((task, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <div className="col-lg-6" >
                                                                            <div className={`project-category-single alt`}
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => Popup(task.id, task.promptsName, task.description, task.promts)}>

                                                                                <div className="project-category-icon"><FiEdit /></div>
                                                                                <div className="project-category-txt">
                                                                                    <h3>{task.promptsName}</h3>
                                                                                    <p
                                                                                        style={{
                                                                                            color: "#FFF",
                                                                                            fontSize: 15
                                                                                        }}
                                                                                        className={`my-anchor-element${index}-all`}
                                                                                    >
                                                                                        {task.description !== "" ? task.description.slice(0, 40) + "..." : onSetPrompts(task.promts).slice(0, 40) + "..."}
                                                                                    </p>
                                                                                    <Tooltip anchorSelect={'.my-anchor-element' + index + '-all'} place="bottom" style={{ width: 400 }}>
                                                                                        {task.description !== "" ? task.description : onSetPrompts(task.promts)}
                                                                                    </Tooltip>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                );
                                                            })) : <div className="col-md-12 text-center">
                                                            {loader ?
                                                                <></>
                                                                : "No Data Found"}
                                                        </div>
                                                }
                                                <div className="col-md-12 text-center">
                                                    {loader ?
                                                        <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#f721a0", fontSize: "30px" }} />
                                                        : ""}
                                                </div>
                                            </div>
                                            {/**</InfiniteScroll> */}
                                        </div>


                                    </div>
                                </div>
                            </div>

                            {
                                selector.user.isClient === "0" ? (
                                    <ModalPopup
                                        show={show}
                                        hidePopup={hidePopup}
                                        dataValue={dataValue}
                                        UserInputs={UserInputs}
                                        setDataValue={setDataValue}
                                        setUserInputs={setUserInputs}
                                        promptsName={promptHeading}
                                        description={descriptionData}
                                        id={id}
                                        isPromptAvail={isPromptAvail}
                                        setIsPromptAvail={setIsPromptAvail}
                                        setTaskName={setTaskName}
                                        taskName={taskName}
                                        prompt={prompt}
                                    />) : ''
                            }
                        </div>


                    </section >
                </InfiniteScroll>


            </div >

            <Footer />

        </>
    )
}

export default Create;