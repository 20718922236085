import React, { useState } from "react";
import LoginFooter from "./LoginFooter";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import logo from '../../images/LOGO.png';
import TitleBar from "../TitleBar";
import { resetPassword } from "../../Actions/AuthAction";

const ResetPassword = () => {

    let location = useLocation()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loader, setLoader] = useState(false);
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const [password, setPassword] = useState({
        password: null,
        confirm_password: null,
        password_token: queryString.parse(location.search).token
    })

    const onInputChange = (e) => {
        setPasswordMsg({ ...passwordMsg, msg: "" })
        setPassword({ ...password, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (password.password) {
            if (pattern.test(password.password)) {
                if ((password.password === password.confirm_password)) {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                    setLoader(true);
                    dispatch(resetPassword(password, setLoader, navigate));
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
                }

            } else {
                setPasswordMsg({ ...passwordMsg, msg:"Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character and be at least 8 characters long.", validation: true })
            }
        }
    }
 
    return (
        <>

            <TitleBar title="Reset Password" />

            <header className="loginHeader">
                <div className="container">
                    <div className="loginLogo">
                        <img src={logo} alt="logo" />
                    </div>
                </div>
            </header>

            <div className="login-container">
                <div className="container full-height-container">
                    <div className="row full-height-row align-items-center">
                        <div className="col-lg-6">
                            <div className="login-container-left">
                                <h2>Welcome <span>Back!</span></h2>
                                <p>Solve Your Business Problems in a Snap with A.I. Automation for Effortless Solutions.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-container-right">
                                <div className="login-container-right-main">
                                    <h2><span>Reset</span> Password</h2>
                                    <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="form-group">
                                            <label>New Password</label>
                                            <input type="password" name="password" className="form-control"
                                                placeholder="************"
                                                required onChange={(e) => onInputChange(e)}
                                                minLength={6}
                                                maxLength={32}
                                            />
                                            {/* {msg ? (
                                                <p style={{ fontSize: 13 }}>Password is valid!</p>
                                            ) : (
                                                <p style={{ fontSize: 13 }}>
                                                    Password must contain at least one lowercase letter, one uppercase
                                                    letter, one digit, and be at least 6 characters long.
                                                </p>
                                            )} */}

                                        </div>
                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <input type="password" name="confirm_password" className="form-control"
                                                placeholder="************" required
                                                onChange={(e) => onInputChange(e)
                                                }
                                                minLength={6}
                                                maxLength={32}
                                            />
                                        </div>
                                        <p style={{ fontSize: 13, margin: "5px" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-block btn-primary themeBtn" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Reset Password
                                            </button>
                                        </div>

                                        <div className="form-group">
                                            <p className="forgotLink">Login ? <Link to="/login" > Click here</Link></p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoginFooter />
        </>
    )
}


export default ResetPassword;