import React, { useState } from "react";
import LoginFooter from "./LoginFooter";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Alert from "../Alert";
import logo from '../../images/LOGO.png';
import TitleBar from "../TitleBar";
import { forgetPassword } from "../../Actions/AuthAction";

const ForgetPassword = () => {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [emailDetails, setEmailDetails] = useState({
        email: ''
    })

    const onInputChange = (e) => {
        setEmailDetails({ ...emailDetails, email: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch(forgetPassword(emailDetails, setLoader));
    }

    return (
        <>

            <TitleBar title="Forget Password" />
            <Alert />

            <header className="loginHeader">
                <div className="container">
                    <div className="loginLogo">
                        <img src={logo} alt="" />
                    </div>
                </div>
            </header>

            <div className="login-container">
                <div className="container full-height-container">
                    <div className="row full-height-row align-items-center">
                        <div className="col-lg-6">
                            <div className="login-container-left">
                                <h2>Welcome <span>Back!</span></h2>
                                <p>Solve Your Business Problems in a Snap with A.I. Automation for Effortless Solutions.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-container-right">
                                <div className="login-container-right-main">
                                    <h2><span>Enter </span> Your Email Address</h2>
                                    <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <input type="email" className="form-control" placeholder="Enter Email"
                                                required
                                                onChange={(e) => onInputChange(e)}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-block btn-primary themeBtn" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Submit
                                            </button>
                                        </div>

                                        <div className="form-group">
                                            <p className="forgotLink"> <Link to="/login" className="text-center "> Remember Login ?</Link></p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LoginFooter />

        </>
    )
}

export default ForgetPassword;